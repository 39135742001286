import React from 'react';

export const InstagramIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <g clipPath="url(#clip0_232_874)">
          <path d="M11.1241 2.25049H4.78186C3.03049 2.25049 1.61072 3.67026 1.61072 5.42163V11.7639C1.61072 13.5153 3.03049 14.9351 4.78186 14.9351H11.1241C12.8755 14.9351 14.2953 13.5153 14.2953 11.7639V5.42163C14.2953 3.67026 12.8755 2.25049 11.1241 2.25049Z" stroke="#7F7B7B" strokeWidth="1.26846" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10.4899 8.19311C10.5682 8.72095 10.478 9.26002 10.2323 9.73366C9.98652 10.2073 9.59766 10.5914 9.12103 10.8313C8.64439 11.0712 8.10425 11.1547 7.57742 11.0699C7.05059 10.9852 6.56391 10.7364 6.18659 10.3591C5.80927 9.98179 5.56054 9.4951 5.47576 8.96827C5.39099 8.44145 5.4745 7.9013 5.7144 7.42467C5.9543 6.94803 6.33839 6.55918 6.81203 6.31341C7.28567 6.06765 7.82475 5.97749 8.35258 6.05576C8.89099 6.1356 9.38945 6.38649 9.77433 6.77137C10.1592 7.15624 10.4101 7.6547 10.4899 8.19311Z" stroke="#7F7B7B" strokeWidth="1.26846" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.4413 5.10474H11.4481" stroke="#7F7B7B" strokeWidth="1.26846" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_232_874">
            <rect width="15.2215" height="15.2215" fill="white" transform="translate(0.342285 0.982178)"/>
          </clipPath>
        </defs>
      </svg>
    );
}


export const LinkedinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
  <g clipPath="url(#clip0_232_879)">
    <path d="M4.34481 14.5387H1.46848V5.27613H4.34481V14.5387ZM2.9051 4.01263C1.98534 4.01263 1.23932 3.25082 1.23932 2.33106C1.23932 1.88927 1.41482 1.46557 1.72721 1.15318C2.03961 0.840784 2.46331 0.665283 2.9051 0.665283C3.34689 0.665283 3.77059 0.840784 4.08298 1.15318C4.39537 1.46557 4.57087 1.88927 4.57087 2.33106C4.57087 3.25082 3.82454 4.01263 2.9051 4.01263ZM15.11 14.5387H12.2398V10.0297C12.2398 8.95515 12.2182 7.57707 10.7444 7.57707C9.24893 7.57707 9.01976 8.74457 9.01976 9.95233V14.5387H6.14654V5.27613H8.90518V6.53964H8.94544C9.32944 5.81188 10.2675 5.04387 11.6669 5.04387C14.5779 5.04387 15.1131 6.9608 15.1131 9.45064V14.5387H15.11Z" fill="#7F7B7B"/>
  </g>
  <defs>
    <clipPath id="clip0_232_879">
      <rect width="15.8557" height="15.8557" fill="white" transform="translate(0.248352 0.665039)"/>
    </clipPath>
  </defs>
</svg>
    );
}


export const MenssagerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
  <path d="M14.1073 8.27572C14.1095 9.11282 13.9139 9.9386 13.5365 10.6858C13.089 11.5812 12.4011 12.3343 11.5498 12.8607C10.6984 13.3872 9.71736 13.6663 8.71639 13.6667C7.87929 13.6688 7.05352 13.4733 6.30632 13.0959L2.69122 14.3009L3.89626 10.6858C3.51885 9.9386 3.32327 9.11282 3.32545 8.27572C3.32584 7.27476 3.6049 6.29367 4.13138 5.44234C4.65785 4.59102 5.41095 3.90308 6.30632 3.45559C7.05352 3.07818 7.87929 2.8826 8.71639 2.88478H9.0335C10.3555 2.95771 11.6041 3.51569 12.5402 4.45187C13.4764 5.38805 14.0344 6.63666 14.1073 7.95861V8.27572Z" stroke="#7F7B7B" strokeWidth="1.26846" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    );
}


export const EmailIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <g clipPath="url(#clip0_232_884)">
          <path d="M5.099 13.8781C4.39431 13.8781 3.68961 13.2615 3.33726 12.9972C1.57551 11.764 1.04699 11.3236 0.694641 11.0593V14.759C0.694641 15.2454 1.08911 15.6399 1.57551 15.6399H8.62249C9.1089 15.6399 9.50337 15.2454 9.50337 14.759V11.0593C9.15102 11.3236 8.62249 11.764 6.86075 12.9972C6.5084 13.2615 5.8037 13.8781 5.099 13.8781ZM8.62249 8.59288H1.57551C1.08911 8.59288 0.694641 8.98734 0.694641 9.47375V9.91419C1.39934 10.4427 1.31125 10.4427 3.86578 12.2925C4.13004 12.4687 4.65857 12.9972 5.099 12.9972C5.53944 12.9972 6.06796 12.4687 6.33223 12.3806C8.88675 10.5308 8.79867 10.5308 9.50337 10.0023V9.47375C9.50337 8.98734 9.1089 8.59288 8.62249 8.59288ZM15.6695 5.95026H6.86075C6.37434 5.95026 5.97988 6.34473 5.97988 6.83113V7.71201H8.62249C9.53667 7.71201 10.2904 8.41175 10.3763 9.30363L10.3842 9.29758V12.9972H15.6695C16.1559 12.9972 16.5503 12.6028 16.5503 12.1164V6.83113C16.5503 6.34473 16.1559 5.95026 15.6695 5.95026ZM14.7886 9.47375H13.0269V7.71201H14.7886V9.47375ZM5.099 6.83113C5.099 5.8597 5.88931 5.06939 6.86075 5.06939H13.0269V2.42677C13.0269 1.94036 12.6324 1.5459 12.146 1.5459H3.33726C2.85085 1.5459 2.45639 1.94036 2.45639 2.42677V7.71201H5.099V6.83113Z" fill="#7F7B7B"/>
        </g>
        <defs>
          <clipPath id="clip0_232_884">
            <rect width="15.8557" height="15.8557" fill="white" transform="translate(0.694641 0.665039)"/>
          </clipPath>
        </defs>
      </svg>
    );
}