import React from 'react';

export const IconLightBulb = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M12.28 22.54C13.38 22.54 14.28 21.64 14.28 20.54H10.28C10.28 21.64 11.18 22.54 12.28 22.54Z" fill="#EB8155"/>
  <path d="M9.28 19.54H15.28C15.83 19.54 16.28 19.09 16.28 18.54C16.28 17.99 15.83 17.54 15.28 17.54H9.28C8.73 17.54 8.28 17.99 8.28 18.54C8.28 19.09 8.73 19.54 9.28 19.54Z" fill="#EB8155"/>
  <path d="M12.28 2.54004C8.14 2.54004 4.78 5.90004 4.78 10.04C4.78 13.86 7.44 15.9 8.55 16.54H16.01C17.12 15.9 19.78 13.86 19.78 10.04C19.78 5.90004 16.42 2.54004 12.28 2.54004Z" fill="#EB8155"/>
</svg>
    );
}


export const IconShield = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23" fill="none">
        <path d="M10.2799 22.305C9.89117 22.305 9.51353 22.2177 9.16924 22.0321L8.83603 21.8464C3.54927 18.9096 0.283928 13.3961 0.283928 7.43491V7.28207C0.283928 6.49598 0.70598 5.76448 1.39459 5.37143L9.16924 1.06981C9.85784 0.676764 10.7019 0.676764 11.3906 1.06981L19.1651 5.37143C19.8538 5.76448 20.287 6.49598 20.2759 7.28207V7.43491C20.2759 13.407 16.9994 18.9096 11.7015 21.8464L11.3683 22.0321C11.0351 22.2068 10.6575 22.305 10.2799 22.305Z" fill="#6CBF84"/>
      </svg>
    );
}


export const IconBuildCircle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 2.54004C6.48 2.54004 2 7.02004 2 12.54C2 18.06 6.48 22.54 12 22.54C17.52 22.54 22 18.06 22 12.54C22 7.02004 17.52 2.54004 12 2.54004ZM16.54 16.39L15.85 17.08C15.46 17.47 14.83 17.47 14.44 17.08L11.39 14.03C10.17 14.46 8.75 14.2 7.77 13.22C6.66 12.11 6.47 10.43 7.18 9.12004L9.53 11.47L10.94 10.06L8.58 7.71004C9.9 7.00004 11.57 7.19004 12.68 8.30004C13.66 9.28004 13.92 10.7 13.49 11.92L16.54 14.97C16.93 15.36 16.93 16 16.54 16.39Z" fill="#F25685"/>
      </svg>
    );
}


export const IconData = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M22 12.54C22 7.02004 17.52 2.54004 12 2.54004C6.48 2.54004 2 7.02004 2 12.54C2 13.87 2.26 15.15 2.74 16.31L7.35 11.69C7.72 11.32 8.3 11.3 8.7 11.63L11.3 13.82L14.58 10.54H14C13.45 10.54 13 10.09 13 9.54004C13 8.99004 13.45 8.54004 14 8.54004H17C17.55 8.54004 18 8.99004 18 9.54004V12.54C18 13.09 17.55 13.54 17 13.54C16.45 13.54 16 13.09 16 12.54V11.96L12.06 15.89C11.69 16.26 11.1 16.28 10.71 15.94L8.12 13.75L3.72 18.15C5.52 20.8 8.56 22.54 12 22.54H20C21.1 22.54 22 21.64 22 20.54V12.54ZM19.5 21.04C18.95 21.04 18.5 20.59 18.5 20.04C18.5 19.49 18.95 19.04 19.5 19.04C20.05 19.04 20.5 19.49 20.5 20.04C20.5 20.59 20.05 21.04 19.5 21.04Z" fill="#5682F2"/>
</svg>
    );
}