import React from 'react';

export const Logo = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="231" height="23" viewBox="0 0 231 23" fill="none">
      <path d="M76.6097 22.3965C74.9152 22.3965 73.2913 22.1746 71.738 21.7308C70.1846 21.2668 68.9339 20.6717 67.9858 19.9455L69.6501 16.2538C70.5578 16.8993 71.627 17.4339 72.8576 17.8576C74.1083 18.261 75.3691 18.4627 76.64 18.4627C77.6083 18.4627 78.385 18.372 78.97 18.1904C79.5752 17.9887 80.019 17.7163 80.3014 17.3734C80.5838 17.0305 80.725 16.6371 80.725 16.1933C80.725 15.6284 80.5032 15.1846 80.0593 14.8619C79.6155 14.5189 79.0305 14.2466 78.3043 14.0449C77.5781 13.823 76.7711 13.6212 75.8835 13.4397C75.0161 13.2379 74.1386 12.9959 73.2509 12.7134C72.3835 12.431 71.5867 12.0679 70.8605 11.6241C70.1342 11.1803 69.5391 10.5953 69.0751 9.86905C68.6313 9.14282 68.4094 8.21486 68.4094 7.08517C68.4094 5.87479 68.7322 4.77536 69.3777 3.78688C70.0434 2.77823 71.0319 1.9814 72.3432 1.39638C73.6746 0.791194 75.3389 0.488599 77.336 0.488599C78.6674 0.488599 79.9786 0.649982 81.2697 0.97275C82.5608 1.27534 83.7006 1.73932 84.689 2.36469L83.1761 6.08661C82.1876 5.52176 81.1991 5.10822 80.2106 4.84597C79.2222 4.56355 78.2539 4.42233 77.3057 4.42233C76.3576 4.42233 75.5809 4.53329 74.9757 4.75519C74.3705 4.97709 73.9368 5.2696 73.6746 5.63271C73.4123 5.97566 73.2812 6.37912 73.2812 6.8431C73.2812 7.38777 73.5031 7.83157 73.9469 8.17451C74.3907 8.49728 74.9757 8.75953 75.702 8.96126C76.4282 9.16299 77.225 9.36472 78.0925 9.56645C78.9801 9.76818 79.8576 10.0002 80.725 10.2624C81.6127 10.5247 82.4196 10.8777 83.1458 11.3215C83.872 11.7653 84.457 12.3503 84.9009 13.0766C85.3648 13.8028 85.5968 14.7207 85.5968 15.8302C85.5968 17.0204 85.264 18.1097 84.5983 19.0982C83.9326 20.0867 82.934 20.8835 81.6026 21.4887C80.2913 22.0939 78.6271 22.3965 76.6097 22.3965Z" fill="#9E5AFF"/>
      <path d="M0 0.851712V4.84597H8.8963C10.4093 4.84597 11.539 5.16874 12.2854 5.81427C13.0318 6.45981 13.405 7.36759 13.405 8.53763C13.405 9.68749 13.0318 10.5852 12.2854 11.2307C11.539 11.8763 10.4093 12.199 8.8963 12.199H0V22.0334H4.90204V16.1933H9.16863C11.0649 16.1933 12.6989 15.8907 14.0707 15.2855C15.4424 14.6601 16.5015 13.7725 17.2479 12.6227C17.9943 11.4728 18.3675 10.1111 18.3675 8.53763C18.3675 6.94396 17.9943 5.5722 17.2479 4.42233C16.5015 3.27247 15.4424 2.39495 14.0707 1.78976C12.6989 1.16439 11.0649 0.851712 9.16863 0.851712H0Z" fill="#9E5AFF"/>
      <path d="M21.8672 12.2293V22.0334H26.7693V16.1328H31.0359C31.1131 16.1328 31.1899 16.1323 31.2663 16.1313L35.3327 22.0334H40.6281L35.8964 15.2432L35.9379 15.225C37.3097 14.6198 38.3687 13.7523 39.1151 12.6227C39.8615 11.4728 40.2347 10.1111 40.2347 8.53763C40.2347 6.94396 39.8615 5.5722 39.1151 4.42233C38.3687 3.27247 37.3097 2.39495 35.9379 1.78976C34.5661 1.16439 32.9321 0.851712 31.0359 0.851712H21.8672V4.84597H30.7635C32.2765 4.84597 33.4062 5.16874 34.1526 5.81427C34.899 6.45981 35.2722 7.36759 35.2722 8.53763C35.2722 9.68749 34.899 10.5953 34.1526 11.261C33.4062 11.9065 32.2765 12.2293 30.7635 12.2293H21.8672Z" fill="#9E5AFF"/>
      <path d="M95.9061 22.3965C94.2116 22.3965 92.5876 22.1746 91.0343 21.7308C89.481 21.2668 88.2303 20.6717 87.2821 19.9455L88.9464 16.2538C89.8542 16.8993 90.9234 17.4339 92.1539 17.8576C93.4046 18.261 94.6655 18.4627 95.9364 18.4627C96.9047 18.4627 97.6813 18.372 98.2663 18.1904C98.8715 17.9887 99.3153 17.7163 99.5978 17.3734C99.8802 17.0305 100.021 16.6371 100.021 16.1933C100.021 15.6284 99.7995 15.1846 99.3557 14.8619C98.9119 14.5189 98.3269 14.2466 97.6006 14.0449C96.8744 13.823 96.0675 13.6212 95.1799 13.4397C94.3124 13.2379 93.4349 12.9959 92.5473 12.7134C91.6799 12.431 90.883 12.0679 90.1568 11.6241C89.4306 11.1803 88.8355 10.5953 88.3715 9.86905C87.9277 9.14282 87.7058 8.21486 87.7058 7.08517C87.7058 5.87479 88.0285 4.77536 88.6741 3.78688C89.3398 2.77823 90.3283 1.9814 91.6395 1.39638C92.9709 0.791194 94.6352 0.488599 96.6323 0.488599C97.9637 0.488599 99.275 0.649982 100.566 0.97275C101.857 1.27534 102.997 1.73932 103.985 2.36469L102.472 6.08661C101.484 5.52176 100.495 5.10822 99.507 4.84597C98.5185 4.56355 97.5502 4.42233 96.6021 4.42233C95.6539 4.42233 94.8773 4.53329 94.2721 4.75519C93.6669 4.97709 93.2332 5.2696 92.9709 5.63271C92.7087 5.97566 92.5776 6.37912 92.5776 6.8431C92.5776 7.38777 92.7995 7.83157 93.2433 8.17451C93.6871 8.49728 94.2721 8.75953 94.9983 8.96126C95.7245 9.16299 96.5214 9.36472 97.3888 9.56645C98.2764 9.76818 99.154 10.0002 100.021 10.2624C100.909 10.5247 101.716 10.8777 102.442 11.3215C103.168 11.7653 103.753 12.3503 104.197 13.0766C104.661 13.8028 104.893 14.7207 104.893 15.8302C104.893 17.0204 104.56 18.1097 103.895 19.0982C103.229 20.0867 102.23 20.8835 100.899 21.4887C99.5877 22.0939 97.9234 22.3965 95.9061 22.3965Z" fill="#9E5AFF"/>
      <path d="M108.243 0.851712H113.145V22.0334H108.243V0.851712Z" fill="#9E5AFF"/>
      <path d="M118.172 0.851712H122.226L130.16 14.0041L137.961 0.851712H141.986L142.046 22.0334H137.447L137.42 9.32006L131.183 19.7942H128.974L122.771 9.59392V22.0334H118.172V0.851712Z" fill="#9E5AFF"/>
      <path d="M153.729 0.851712H158.57L168.042 22.0334H162.898L156.118 5.66598L149.311 22.0334H144.288L153.729 0.851712Z" fill="#9E5AFF"/>
      <path d="M54.2489 7.03886C53.9207 6.7106 53.3885 6.71061 53.0602 7.03886C52.732 7.36711 52.732 7.89931 53.0602 8.22756L55.6879 10.8553L49.592 10.8553C49.1278 10.8553 48.7515 11.2316 48.7515 11.6958C48.7515 12.16 49.1278 12.5364 49.592 12.5364L55.6879 12.5364L53.0602 15.1641C52.732 15.4923 52.732 16.0245 53.0602 16.3528C53.3885 16.6811 53.9207 16.6811 54.2489 16.3528L58.3115 12.2902C58.6398 11.9619 58.6398 11.4297 58.3115 11.1015L54.2489 7.03886Z" fill="#9E5AFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.829 1.32914C48.0073 1.32914 43.2879 6.04854 43.2879 11.8702C43.2879 17.6919 48.0073 22.4113 53.829 22.4113C59.6507 22.4113 64.3701 17.6919 64.3701 11.8702C64.3701 6.04854 59.6507 1.32914 53.829 1.32914ZM44.969 11.8702C44.969 6.97698 48.9358 3.01023 53.829 3.01023C58.7222 3.01023 62.689 6.97698 62.689 11.8702C62.689 16.7635 58.7222 20.7302 53.829 20.7302C48.9358 20.7302 44.969 16.7635 44.969 11.8702Z" fill="#9E5AFF"/>
      <path d="M197.469 21.95C194.515 21.95 192.205 21.1339 190.54 19.5019C188.874 17.8699 188.042 15.5398 188.042 12.5117V0.949951H192.859V12.3348C192.859 14.3011 193.266 15.7168 194.079 16.582C194.891 17.4471 196.031 17.8797 197.498 17.8797C198.966 17.8797 200.106 17.4471 200.918 16.582C201.731 15.7168 202.138 14.3011 202.138 12.3348V0.949951H206.896V12.5117C206.896 15.5398 206.063 17.8699 204.398 19.5019C202.732 21.1339 200.423 21.95 197.469 21.95Z" fill="#9E5AFF"/>
      <path d="M208.987 21.596L217.938 9.35585V12.8362L209.374 0.949951H214.845L220.763 9.23787L218.474 9.26737L224.302 0.949951H229.536L221.031 12.6297V9.20838L230.042 21.596H224.481L218.355 12.8362H220.555L214.518 21.596H208.987Z" fill="#9E5AFF"/>
    </svg>

  );
}
